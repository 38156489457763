import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    color: {
        primary: '#00D9DE',
        secondary: '#FFFFFF',
        accent: '#FD397A',
        dark: '#1F2547',
        grey: '#818C99',
        disabled: '#d3d5db',
        error: '#FD397A',
        neutral100: '#F5F5F5',
        neutral200: '#F6F8FA',
        neutral300: '#A9ADBC',
        neutral400: '#ECECF0',
        neutral500: '#818C99',
    },
    fontSize: {
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.25rem',
        xl: '1.5rem',
        xxl: '2rem',
        xxxl: '2.625rem',
        xxxxl: '3.25rem',
    },
    background: {
        mainBackground: '#F6F8FAFF',
    },
};
