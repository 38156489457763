import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { SERVER_ERROR, STATUS_CODE_OK } from '@/constants/settings';

/* eslint-disable camelcase */
import { ResourceService, TErrorData, TReadParamsProperties } from '../baseServices/resource-service';

export const UserArentaService = ResourceService('users');

export type TIinParams = {
    iin: string;
};

export type TIinResponse = {
    uuid: string;
    iin: string;
};

export type TUserByIinResponse = {
    exists: boolean;
    login: string;
};

export type TResponseCreatedUser = {
    id: string;
};

export type TResponseUserCallback = {
    phoneNumber: string;
};

export const createUser = async ({
    login,
    password,
    verificationCode,
}: {
    login: string;
    password: string;
    verificationCode: string;
}): Promise<TResponseCreatedUser | TErrorData> => {
    try {
        const responseIsValidIin = await UserArentaService.create<TResponseCreatedUser, TReadParamsProperties>({
            data: {
                login,
                password,
                verificationCode,
            },
        });

        return responseIsValidIin;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};

export const updateUser = async ({
    login,
    password,
    verificationCode,
}: {
    login: string;
    password: string;
    verificationCode: string;
}): Promise<TResponseCreatedUser | number> => {
    try {
        const responseIsValidIin = await UserArentaService.patch<TResponseCreatedUser, TReadParamsProperties>({
            identifier: login,
            data: {
                password,
                verificationCode,
            },
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        });

        return responseIsValidIin;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.status === STATUS_CODE_OK ? STATUS_CODE_OK : SERVER_ERROR;
    }
};
