import { createSlice } from '@reduxjs/toolkit';

import { Product } from '@/store/reducers/product/types';

const initialState: Product = {
    productId: 1,
    onAddToBasket: null,
    onAddToFavorites: null,
};

export const productSlice = createSlice({
    name: 'product', //FIXME maybe should be stored in constants
    initialState,
    reducers: {
        reset: () => initialState,
    },
});

export const { reset } = productSlice.actions;

export default productSlice.reducer;
