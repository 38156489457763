import { styled } from 'styled-components';

import { rowBothMiddle } from '@/components/_shared/mixins.styled';

export const Wrapper = styled.div`
    ${rowBothMiddle};
    flex-direction: column;

    .smile {
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    .message {
        text-align: center;
        margin-top: 32px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 15.6px */
    }

    .action {
        width: 100%;
        margin-top: 16px;
    }
`;
