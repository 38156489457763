/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TSettingsState = {
    technicalError: { error: unknown } | null;
    isDefaultSettingsLoaded?: boolean;
};

const initialState: TSettingsState = {
    technicalError: null,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTechnicalError: (state, action: PayloadAction<{ error: unknown } | null>) => {
            state.technicalError = action.payload;
        },
        setIsDefaultSettingsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isDefaultSettingsLoaded = action.payload;
        },
    },
});

export const { setTechnicalError, setIsDefaultSettingsLoaded } = settingsSlice.actions;

export default settingsSlice.reducer;
