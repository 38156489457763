import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getContentBlocks } from '@/api/arenta/content-block-service';
import { TContentBlock } from '@/store/reducers/content-blocks/types';
import { setTechnicalError } from '@/store/reducers/settings/slice';

export const loadContentBlocks = createAsyncThunk<
    { calltrackPhone?: string; data: TContentBlock[] },
    { locale: string; phone?: string }
>('contentBlocks/loadContentBlocks', async function ({ locale }: { locale: string }, { rejectWithValue, dispatch }) {
    try {
        const data = await getContentBlocks(locale);

        return { data };
    } catch (err) {
        /* eslint-disable no-console */
        console.error('loadContentBlocks error', err);

        const error = err as AxiosError;

        captureException(error);

        // TODO костыль ARKZ-235
        dispatch(setTechnicalError({ error }));

        return rejectWithValue(error);
    }
});
