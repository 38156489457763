import { createSlice } from '@reduxjs/toolkit';

import { TErrorState } from './types';

const initialState: TErrorState = {
    internalServerError: false,
};
export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        toggleInternalServerError(state, action) {
            state.internalServerError = action.payload;
        },
    },
});

export const { toggleInternalServerError } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
