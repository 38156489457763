import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getAllCategories, getRentProducts } from '@/api/sass-credit/rentService';
import { EStatusProduct } from '@/api/sass-credit/rentService/types';
import { ILoadPopularProductsPayload } from '@/store/reducers/rent/types';
import { setTechnicalError } from '@/store/reducers/settings/slice';
import { TCategoryProduct, TRentProduct } from '@/types/products';

export const loadPopularProducts = createAsyncThunk<
    TRentProduct[],
    ILoadPopularProductsPayload,
    { rejectValue: unknown }
>('rent/loadPopularProducts', async function ({ cityKatoid }: ILoadPopularProductsPayload, { rejectWithValue }) {
    try {
        const { data } = await getRentProducts({
            statuses: [EStatusProduct.ACTIVE],
            popular: true,
            katoid: cityKatoid,
        });
        return data;
    } catch (err) {
        /* eslint-disable no-console */
        console.error('loadPopularProducts error', err);
        const error = err as AxiosError;
        captureException(error);

        return rejectWithValue(error);
    }
});

export const loadAllCategories = createAsyncThunk<TCategoryProduct[], undefined>(
    'rent/loadAllCategories',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            return await getAllCategories();
        } catch (err) {
            /* eslint-disable no-console */
            console.error('loadAllCategories error', err);

            const error = err as AxiosError;

            captureException(error);

            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
