import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    /* Reset start */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
      display: block;
    }

    body {
      line-height: 1;
    }

    li {
        list-style: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ol, ul {
      list-style: none;
    }
    

    blockquote, q {
      quotes: none;
    }

    blockquote::before, blockquote::after,
    q::before, q::after {
      content: '';
      content: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    /* Reset end */
    
    html,
    body {
      font-family: 'Rounded Mplus 1c', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      background-color: ${({ theme }) => theme.color.neutral200};
      color: ${({ theme }) => theme.color.dark};
      min-height: 100vh;
    }
    
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${({ theme }) => theme.color.primary};
      }
    }
    
   
    * {
      box-sizing: border-box;
    }
`;
