import { Action, AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { createWrapper, HYDRATE, Context } from 'next-redux-wrapper';

import { isProduction } from '@/shared/config';

import { rootReducer } from './reducers';
import { TRootState } from './types';

enableMapSet();

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const reducer = (state: any, action: AnyAction) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state,
            ...action.payload,
        };

        return nextState;
    }

    return rootReducer(state, action);
};

export const initStore = configureStore({
    reducer,
    devTools: !isProduction(),
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false })],
});

/* eslint-disable @typescript-eslint/no-unused-vars */
export const makeStore = (context: Context) => initStore;

export const storeForType = configureStore({ reducer: rootReducer });

export type Store = ReturnType<typeof makeStore>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, TRootState, unknown, Action<string>>;

export const wrapperReduxToolkit = createWrapper(makeStore, { debug: false });
