/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { loadContentBlocks } from '@/store/reducers/content-blocks/action-creators';

import { TContentBlocksState, TContentBlockTypes } from './types';

const initialState: TContentBlocksState = {
    contentBlocks: { data: null, isFetching: false, isLoaded: false },
};

export const contentBlocksSlice = createSlice({
    name: 'contentBlocks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadContentBlocks.pending, (state) => {
                state.contentBlocks.isFetching = true;
                state.contentBlocks.isLoaded = false;
            })
            .addCase(loadContentBlocks.fulfilled, (state, action) => {
                const objContentBlocks: Partial<TContentBlockTypes> = {};
                let instagram = '/';

                action.payload.data.forEach((el) => {
                    if (el.code === 'legal_address') {
                        objContentBlocks.legal_address = el;
                    } else if (el.code === 'contacts') {
                        objContentBlocks.contacts = el;
                    } else if (el.code === 'instagram') {
                        instagram = el.content?.match(/href="([^"]*)/)?.[1] || '/';
                    }
                });

                objContentBlocks.instagramLink = instagram;
                state.contentBlocks.isFetching = false;
                state.contentBlocks.isLoaded = true;
                state.contentBlocks.data = objContentBlocks as TContentBlockTypes;
            })
            .addCase(loadContentBlocks.rejected, (state) => {
                state.contentBlocks.isFetching = false;
                state.contentBlocks.isLoaded = false;
                state.contentBlocks.data = null;
            });
    },
});

export default contentBlocksSlice.reducer;
