import { useEffect, useCallback, useRef } from 'react';

export function useMounted(): () => boolean {
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;

        return () => {
            ref.current = false;
        };
    }, []);

    return useCallback(() => ref.current, [ref]);
}
