import { createSlice } from '@reduxjs/toolkit';

import { TCoordinates } from '@/components/modules/office/types';

import { IOfficeState } from './types';

const initialState: IOfficeState = {
    coordinates: null,
};

export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {
        setCoordinates(state, action) {
            state.coordinates = action.payload as TCoordinates;
        },
        restoreCoordinates(state) {
            state.coordinates = null;
        },
    },
});

export const { setCoordinates, restoreCoordinates } = officeSlice.actions;

export default officeSlice.reducer;
