/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { loadCreditProducts } from '@/store/reducers/credit-products/action-creators';
import { TCreditProductsState } from '@/store/reducers/credit-products/types';

const initialState: TCreditProductsState = {
    creditProducts: { data: [], isFetching: false, isLoaded: false },
};

export const mutateDateStatus = (
    type: 'pending' | 'fulfilled',
    stateEntity: { isFetching: boolean; isLoaded: boolean },
): void => {
    if (type === 'pending') {
        stateEntity.isFetching = true;

        stateEntity.isLoaded = false;
    } else {
        stateEntity.isFetching = false;

        stateEntity.isLoaded = true;
    }
};

export const creditProductSlice = createSlice({
    name: 'creditProducts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCreditProducts.pending, (state) => {
                state.creditProducts.isFetching = true;

                state.creditProducts.isLoaded = false;
            })
            .addCase(loadCreditProducts.fulfilled, (state, action) => {
                state.creditProducts.isFetching = false;

                state.creditProducts.isLoaded = true;

                state.creditProducts.data = action.payload;
            });
    },
});

export default creditProductSlice.reducer;
