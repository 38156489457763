import { useEffect } from 'react';

import { PUBLIC_PHONE_NUMBER } from '@/constants/settings';
import { useAppDispatch } from '@/store/hooks';

import { utmSourceService } from '@/shared/hooks/use-utm-source-service';

import { loadCallTrackPhone } from '../store/action-creators';
import { setCallTrackPhone } from '../store/slice';
import { getCallTrackPhoneCookie } from '../utils';

export const useCallTrackInit = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            const utmSource = utmSourceService.getParamUtmSource();
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 1);

            if (!utmSourceService.isUtmSourceUrl()) {
                const allReadyCookedCallTrackPhone = getCallTrackPhoneCookie();

                if (!allReadyCookedCallTrackPhone || allReadyCookedCallTrackPhone === PUBLIC_PHONE_NUMBER) {
                    if (utmSource) {
                        await dispatch(loadCallTrackPhone({ utmSource }));
                    } else {
                        await dispatch(setCallTrackPhone(PUBLIC_PHONE_NUMBER));
                    }
                } else {
                    await dispatch(setCallTrackPhone(allReadyCookedCallTrackPhone));
                }
            } else {
                await dispatch(loadCallTrackPhone({ utmSource }));
            }
        })();
    }, []);
};
