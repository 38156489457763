import { FC, ReactElement, ReactNode } from 'react';

import { HeadingTypes } from '@/components/elements/heading/types';

import { theme } from '@/shared/theme';

import { StyledHeading } from './styled';

export type TSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

type Props = {
    as?: HeadingTypes;
    color?: string;
    size?: string;
    sizeMobile?: TSize;
    marginB?: string;
    marginBMobile?: string;
    children?: ReactElement | ReactNode | string;
    className?: string;
    weight?: string;
    center?: boolean;
};

export const Heading: FC<Props> = ({
    as,
    children,
    color = theme.color.dark,
    size = theme.fontSize.xxl,
    sizeMobile = 'xl',
    marginB,
    marginBMobile,
    className,
    weight = '700',
    center = false,
}): JSX.Element => {
    return (
        <StyledHeading
            as={as}
            $center={center}
            $colorElement={color}
            $size={size}
            $sizeMobile={sizeMobile}
            $marginB={marginB}
            $marginBMobile={marginBMobile}
            className={className}
            $weight={weight}
        >
            {children}
        </StyledHeading>
    );
};
