/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { loadCities } from '@/store/reducers/cities/action-creators';
import { TCitiesState } from '@/store/reducers/cities/types';

const initialState: TCitiesState = {
    cities: { data: [], isFetching: false, isLoaded: false },
};

export const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCities.pending, (state) => {
                state.cities.isFetching = true;

                state.cities.isLoaded = false;
            })
            .addCase(loadCities.fulfilled, (state, action) => {
                state.cities.isFetching = false;

                state.cities.isLoaded = true;

                state.cities.data = action.payload.data;
            });
    },
});

export default citiesSlice.reducer;
