import { useIntl } from 'react-intl';

/* eslint-disable @typescript-eslint/no-explicit-any */
type ReturnFunction = (key: string, values?: any) => string;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useTranslate = (): { t: ReturnFunction } => {
    const { formatMessage } = useIntl();

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const t = (key: string, values?: any): string => {
        if (key) {
            return formatMessage({ id: key, defaultMessage: key }, values);
        }
        return '';
    };

    return {
        t,
    };
};
