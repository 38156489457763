import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { RefreshIcon } from '@/components/_icons/refresh-icon';
import { Heading } from '@/components/elements/heading';
import { Button } from '@/components/elements/UI/button';

import { Wrapper } from './styled';

export const DeniedLocalStorage = () => {
    const theme = useTheme();
    const { reload } = useRouter();
    return (
        <Wrapper>
            <div className="content">
                <div className="text">
                    <Heading size={theme.fontSize.lg}>Мы не смогли загрузить страницу 😥</Heading>
                    <span>
                        Пожалуйста, проверьте настройки Вашего браузера и убедитесь, что куки (cookies) и локальное
                        хранилище (localStorage) включены.
                    </span>
                </div>
                <Button variant="dark" icon={<RefreshIcon />} onClick={reload}>
                    Обновить страницу
                </Button>
            </div>
        </Wrapper>
    );
};
