/* eslint-disable camelcase */
import { TCity } from '@/components/modules/choose-city';

export const citiesArray: TCity[] = [
    {
        id: 75,
        name: 'Алматы',
        name_kz: 'Алматы',
        katoid: '750000000',
        slug: 'almaty',
    },
];
