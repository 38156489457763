import { NextRouter, withRouter } from 'next/router';
import { Component, ErrorInfo, ReactNode } from 'react';
import { connect } from 'react-redux';

import { ErrorPage500 } from '@/components/elements/error-page500';
import { TRootStateType } from '@/store';

import { customCaptureException } from '@/shared/lib/custom-capture-exeption';

type TProps = {
    router: NextRouter;
    children?: ReactNode;
} & Partial<TRootStateType>;
type TState = {
    hasError: boolean;
};

class ErrorBoundary extends Component<TProps, TState> {
    public state: TState = {
        hasError: false,
    };

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public static getDerivedStateFromError(_: Error): TState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        /* eslint-disable  no-console */
        console.error('Ошибка в работе компонента или запроса:', error, 'Router:', this.props.router);
        customCaptureException(errorInfo, `Ошибка в работе компонента или запроса: ${error}`);
    }

    public render() {
        if (this.state.hasError || this.props?.error?.internalServerError) {
            return <ErrorPage500 />;
        }

        return this.props.children;
    }
}

const mapStateToProps = function (state: TRootStateType) {
    return {
        error: state.error,
    };
};

export const ErrorWrapper = connect(mapStateToProps)(withRouter(ErrorBoundary));
