import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { PUBLIC_PHONE_NUMBER } from '@/constants/settings';
import { setTechnicalError } from '@/store/reducers/settings/slice';

import { getCalltrackByUtmSource } from '../api/calltrack-service';

export const loadCallTrackPhone = createAsyncThunk(
    'callTrack/loadCallTrackPhone',
    async function ({ utmSource }: { utmSource?: string }, { rejectWithValue, dispatch }) {
        try {
            if (!utmSource) {
                return PUBLIC_PHONE_NUMBER;
            }

            const data = await getCalltrackByUtmSource(utmSource);

            if (!('phone' in data)) {
                return PUBLIC_PHONE_NUMBER;
            }

            return data.phone;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('loadCallTrackPhone getInitialProps error', err);

            const error = err as AxiosError;

            captureException(error);

            // TODO костыль ARKZ-235
            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
