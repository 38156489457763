import { ResourceService } from '@/api/baseServices/resource-service';

import { TRequestVerificationMessage, TVerificationMessage } from './types';

export const VerificationMessagesService = ResourceService('verification-messages');

export const getVerificationMessage = async (
    params: TRequestVerificationMessage,
): Promise<TVerificationMessage | null> => {
    const { locale, type, verificationMethod } = params;

    try {
        return await VerificationMessagesService.readOne<TVerificationMessage>({
            identifier: `${locale}/${type}/${verificationMethod}`,
        });
    } catch (e) {
        return null;
    }
};
