import { createAsyncThunk } from '@reduxjs/toolkit';

import { getVerificationMessage } from '../api';
import { TRequestVerificationMessage } from '../api/types';

export const loadVerificationMessage = createAsyncThunk(
    'verificationService/loadVerificationMessage',
    async function (params: TRequestVerificationMessage) {
        const response = await getVerificationMessage(params);
        return {
            message: response?.message,
            title: response?.title,
            verificationMethod: params.verificationMethod,
            phone: params.phone,
        };
    },
);
