import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getHeaderMenuSettings } from '@/api/arenta/menu-service';
import { setTechnicalError } from '@/store/reducers/settings/slice';

export const loadMenus = createAsyncThunk(
    'menus/loadMenus',
    async function ({ locale }: { locale: string }, { rejectWithValue, dispatch }) {
        try {
            return await getHeaderMenuSettings(locale);
        } catch (err) {
            /* eslint-disable no-console */
            console.error('loadMenus error', err);

            const error = err as AxiosError;

            captureException(error);

            // TODO костыль ARKZ-235
            dispatch(setTechnicalError({ error }));

            return rejectWithValue(error);
        }
    },
);
