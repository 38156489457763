import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { LogoIcon } from '@/components/_icons/logo-icon';
import { Button } from '@/components/elements/UI/button';

import * as Styled from './styled';

export const ErrorPage500: FC = () => {
    const { reload } = useRouter();

    return (
        <Styled.Wrapper>
            <LogoIcon color="#1F2547" firstLetterColor="#00D9DE" />
            <div className="description">
                <span className="smile"> 😢</span>
                <div>
                    <div className="title">У нас что-то сломалось</div>

                    <div className="text">
                        Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время.
                    </div>
                </div>

                <Button variant="primary" onClick={reload} aria-label="Обновить страницу">
                    Обновить страницу
                </Button>
            </div>
            <div className="contact">
                <span>
                    Вы всегда можете связаться с нами по номеру <b>+7 (700) 007-55-07</b>{' '}
                </span>
                <br />
                <span>
                    Ждем ваши звонки с <b>9:00</b> до <b>21:00</b> каждый день 🤗
                </span>
            </div>
        </Styled.Wrapper>
    );
};
