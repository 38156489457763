// Заменяет дату 22.05.2022 -> 2022.05.22

export const formatDateToReverse = (date: string): string => {
    if (typeof date !== 'string') return '';

    return date.split('.').reverse().join('-');
};

// Заменяет дату 22.05.2022 -> 2022.05.22

export const formatDateToNormal = (date: string): string => {
    if (typeof date !== 'string') return '';

    return date.split('-').reverse().join('.');
};
