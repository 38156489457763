import { NextPageContext } from 'next';

import { APP_BASE_URL } from '@/constants/settings';
import { Cookies } from '@/utils/cookie';
import { queryStringToJSON } from '@/utils/querify';

import { COOKIE_PARAM_NAME, UTM_SOURCE_PARAM_NAME } from '../constants';

const setQueryString = () => {
    let queryString = location.search;

    if (!queryString.includes(UTM_SOURCE_PARAM_NAME)) {
        return;
    }

    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    const expireTime = currentDate.getTime();

    queryString = `${queryString}&expireTime=${expireTime}`;

    const timeCookieSetting = 60 * 60 * 24 * 30;

    Cookies.set(COOKIE_PARAM_NAME, queryString, { maxAge: timeCookieSetting });
};

const getQueryString = (context?: NextPageContext) => {
    const response = Cookies.has(COOKIE_PARAM_NAME, context) ? Cookies.get<string>(COOKIE_PARAM_NAME, context) : '';

    if (response === '') {
        return '';
    }

    const model = queryStringToJSON(response);

    // Если UTM "Протух" то удялем
    if ('expireTime' in model && new Date().getTime() > (model.expireTime as number)) {
        Cookies.remove(COOKIE_PARAM_NAME);

        return '';
    }

    return response ?? '';
};

const getParamUtmSource = (context?: NextPageContext): string => {
    const response = getQueryString(context);
    const result = queryStringToJSON(response);
    return UTM_SOURCE_PARAM_NAME in result ? (result[UTM_SOURCE_PARAM_NAME] as string) : '';
};

const getReferralFromCookie = (context?: NextPageContext): string => {
    const queryString = getQueryString(context) ?? '';
    return `${APP_BASE_URL}${queryString ? `${queryString}` : '/'}`;
};

const isUtmSourceUrl = (): boolean => {
    const queryString = location.search;

    return queryString.includes(UTM_SOURCE_PARAM_NAME);
};

const clear = () => {
    Cookies.remove(COOKIE_PARAM_NAME);
};

export const utmSourceService = {
    setQueryString,
    getQueryString,
    getParamUtmSource,
    getReferralFromCookie,
    isUtmSourceUrl,
    clear,
};
