import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { TCalculatorFormStore } from './types';

const initialState: TCalculatorFormStore = {
    data: {},
};

export const calculationFormSlice = createSlice({
    name: 'calculationForm',
    initialState,
    reducers: {
        saveCalculatorForm(state, action) {
            state.data[action.payload.calculation.id] = action.payload.calculation;
        },
        deleteCalculatorForm(state, action) {
            if (state.data[action.payload]) {
                delete state.data[action.payload];
            }
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            return {
                ...state,
                ...action.payload.calculation,
            };
        },
    },
});

export const { saveCalculatorForm, deleteCalculatorForm } = calculationFormSlice.actions;

export default calculationFormSlice.reducer;
