import { FC, useEffect } from 'react';

import { useRouteChange } from '@/shared/hooks/use-route-change';

import { LoaderRoute } from './loader';

export const RouteChangeSpinner: FC = () => {
    const { isRouteChanging } = useRouteChange();

    useEffect(() => {
        document.body.style.overflowY = isRouteChanging ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [isRouteChanging]);

    if (!isRouteChanging) {
        return null;
    }

    return <LoaderRoute />;
};
