import * as https from 'https';

import axios, { AxiosInstance } from 'axios';

import { TokenService, onResponseError, onResponseSuccess } from '@/api/baseServices/tokenService/token-service';
import { TResponseAndRequest } from '@/types/types';

// TODO configure

class AxiosClassAuth {
    public axiosInstance: AxiosInstance;

    constructor() {
        this.setAxiosInstance();
    }

    setAxiosInstance(): void {
        this.axiosInstance = axios.create({
            baseURL: process.env.API_AUTH_BASE_URL,
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    }

    use(): AxiosInstance {
        return this.axiosInstance;
    }

    setInterceptors(context?: TResponseAndRequest): void {
        this.axiosInstance.interceptors.request.use((config) => {
            const token = TokenService?.getAccessToken(context);

            if (config.headers && token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });

        this.axiosInstance.interceptors.response.use(
            onResponseSuccess,
            onResponseError(this.axiosInstance, { res: context?.res, req: context?.req }),
        );
    }
}

export const axiosClientAuthClass = new AxiosClassAuth();
