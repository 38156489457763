import { AxiosRequestConfig } from 'axios';

import { axiosClientArentaClass } from './../arenta/axios/index';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const apiService = (axiosClient = axiosClientArentaClass) => ({
    get: async function <T>(
        url: string,
        headers?: { [key: string]: string },
        baseURL?: string,
        options?: { [key: string]: string },
    ): Promise<T> {
        const response = await axiosClient.use().get<T | ErrorResponse>(url, {
            headers: headers,
            baseURL,
            ...options,
        });

        return prepareData<T>(response.data);
    },

    post: async function <T, P>(
        url: string,
        data?: P,
        headers?: { [key: string]: string },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        config?: { [key: string]: any },
    ): Promise<T> {
        const response = await axiosClient.use().post<T | ErrorResponse>(url, data, {
            headers,
            ...config,
        });

        return prepareData<T>(response.data);
    },

    put: async function <T, P>(url: string, data: P, headers?: { [key: string]: string }): Promise<T> {
        const response = await axiosClient.use().put<T | ErrorResponse>(url, data, headers);

        return prepareData<T>(response.data);
    },
    patch: async function <T, P>(url: string, data: P, headers?: { [key: string]: string }): Promise<T> {
        const response = await axiosClient.use().patch<T | ErrorResponse>(url, data, { headers });

        return prepareData<T>(response.data);
    },
    delete: async function <T>(url: string, params?: AxiosRequestConfig): Promise<T> {
        const response = await axiosClient.use().delete<T | ErrorResponse>(url, params);

        return prepareData<T>(response.data);
    },
});

function prepareData<T>(response: T | ErrorResponse | string): T {
    if (!response) {
        return response as T;
    }

    if (typeof response === 'string') {
        throw new Error(response);
    }

    if (typeof response === 'object' && 'error' in response && response.error) {
        throw new Error(response.error.detail);
    }

    return response as T;
}

type ErrorResponse = {
    error: {
        status: number;
        title: string;
        detail: string;
    };
};
