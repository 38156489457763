import { styled, css, keyframes } from 'styled-components';

import { rowYMiddle } from '@/components/_shared/mixins.styled';
import { FontSizeButton, VariantButton } from '@/components/elements/UI/button/types';

type TButtonProps = {
    $variant: VariantButton;
    $fontSize: FontSizeButton;
    $outlined: boolean;
};

const getFilledButtonCss = (backgroundColor: string, textColor: string) => css`
    border: none;
    background-color: ${backgroundColor};
    color: ${textColor};

    &:hover {
        filter: brightness(0.98);
    }

    &:active {
        filter: brightness(0.95);
    }
`;

const getOutlinedButtonCss = (borderColor: string, textColor: string) => css`
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    color: ${textColor};

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.54;
    }
`;

const neutralFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.neutral200, theme.color.neutral500));

const primaryFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.primary, theme.color.secondary));

const lightFilledCss = css(({ theme }) => getFilledButtonCss('rgba(255, 255, 255, 0.1)', theme.color.secondary));

const darkFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.dark, theme.color.secondary));

const neutralOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.neutral500, theme.color.neutral500));

const primaryOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.primary, theme.color.primary));

const lightOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.secondary, theme.color.secondary));

const darkOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.dark, theme.color.secondary));

const disabledCss = css`
    pointer-events: none;
    cursor: default;
    color: ${({ theme }) => theme.color.secondary};
    background: #d3d5db;
`;

const filledVariants = {
    primary: primaryFilledCss,
    neutral: neutralFilledCss,
    light: lightFilledCss,
    dark: darkFilledCss,
};

const outlinedVariants = {
    primary: primaryOutlinedCss,
    neutral: neutralOutlinedCss,
    light: lightOutlinedCss,
    dark: darkOutlinedCss,
};

const loader = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const loadingCss = css`
    pointer-events: none;
    background-color: ${({ theme }) => theme.color.disabled};
    color: ${({ theme }) => theme.color.secondary};

    & span.hidden {
        position: relative;
        opacity: 0;
        color: rgb(0 0 0 / 50%);
    }

    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        width: 26px;
        height: 26px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: ${loader} 1.2s infinite;
        border-color: #fff transparent transparent;
        z-index: 10;
        transform: translate(-50%, -50%);
        animation-delay: -0.55s;
    }
`;

export const StyledButton = styled.button<TButtonProps & { $isLoading: boolean; $stretch: boolean; $weight: string }>`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Rounded Mplus 1c', sans-serif;
    font-size: ${({ theme, $fontSize }) => theme.fontSize[$fontSize]};
    font-weight: ${({ $weight }) => $weight};
    line-height: 1.2;
    padding: 9px 16px;
    justify-content: center;
    ${({ $fontSize }) => $fontSize === 'md' && 'height: 42px;'};

    .icon {
        ${rowYMiddle};
        margin-right: 5px;

        &.icon-right {
            margin-right: 0;
            margin-left: 5px;
        }
    }
    ${(props) => props.$stretch && 'width: 100%;'}

    ${({ $variant, $outlined }) => ($outlined ? outlinedVariants[$variant] : filledVariants[$variant])};
    ${(props) => props.disabled && disabledCss};
    ${({ $isLoading }) => $isLoading && loadingCss};
`;
