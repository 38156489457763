import { captureException } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';
import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const customCaptureException = (error: unknown, name: string, captureContext?: CaptureContext) => {
    if (error instanceof AxiosError && error?.response?.data?.message) {
        captureException(
            new AxiosError(error?.response?.data?.message, error?.code, error?.config, error?.request, error?.response),
            captureContext,
        );
    } else {
        captureException(error, captureContext);
    }
    console.error(error);

    // eslint-disable-next-line no-console
    console.groupCollapsed(`error in - %c${name}`, 'color: #FD1C03; font-size: 14px;');
    // eslint-disable-next-line no-console
    console.log('ERROR');
    // eslint-disable-next-line no-console

    // eslint-disable-next-line no-console
    console.groupEnd();
};
