export const getNumberFromString = (page?: string): number => {
    if (!page) return 1;

    return parseInt(page, 10);
};

export const querify = <P>(params: P): string => {
    const url = new URLSearchParams();

    for (const key in params) {
        if (key === 'order') {
            type TParamsArray = {
                [key: string]: string;
            };

            Object.entries(params[key] as TParamsArray).forEach(([name, direction]) => {
                url.append(`${key}[${name}]`, direction);
            });
        } else if (Array.isArray(params[key])) {
            for (const index in params[key]) {
                url.append(`${key}[]`, String(params[key][index]));
            }
        } else {
            url.append(key, String(params[key]));
        }
    }

    return '?' + url;
};

export const queryStringToJSON = (query: string) => {
    if (query.indexOf('?') > -1) {
        query = query.split('?')[1];
    }
    const pairs = query.split('&');
    const result = {};
    pairs.forEach(function (item) {
        const pair = item.split('=') as string[];
        // @ts-ignore
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
};

export const removeParam = (url: string, parameter: string): string => {
    const urlParts = url.split('?');

    if (urlParts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';

        const pars = urlParts[1].split(/[&;]/g);

        for (let i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        if (pars.length > 0) {
            url = urlParts[0] + '?' + pars.join('&');
        } else {
            url = urlParts[0];
        }

        return url;
    }

    return url;
};

export const removeLastSlugPage = (url: string, parameter: string): string => {
    const additionalQuery = url.split('?')[1]?.replace(/[&]?page=.*/gi, '');

    let urlParts = url.split('/');

    if (urlParts.length > 0 && urlParts[urlParts.length - 1].match(new RegExp(`${parameter}`, 'gi'))) {
        urlParts = urlParts.slice(0, urlParts.length - 1);
    }

    return urlParts.join('/').replace(/\?.+/gi, '') + (additionalQuery ? `?${additionalQuery}` : '');
};

export const getQuerySortParam = (selectValueSort: string, routePath: string): string => {
    const [field, typeSort] = selectValueSort.split(':');

    const hasParams = routePath.split('?').length > 1;

    return `${hasParams ? '&' : '?'}sortField=${field}&sortDirection=${typeSort}`;
};

export const getQueryPagination = (page: number, routePath: string): string => {
    const hasParams = routePath.split('?').length > 1;

    return `${routePath}${hasParams ? '&' : '?'}page=${page}`;
};

export const getSlugPagination = (page: number, routePath: string): string => {
    return `${routePath}/page-${page}`;
};
