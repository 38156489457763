export enum ECreditProductType {
    SC = 'sc',
    SITE = 'site',
    MOBILE = 'mobile',
    TERMINAL = 'terminal',
}

export const PHONE_LENGTH = 11;

export const NOT_FOUND_PAGE = 404;

export const SIZE_SHOWN_ARTICLES = 12;

export const SIZE_SHOWN_PRODUCTS = 8;

export const SERVER_ERROR = 500;

export const UNPROCESSABLE_CODE = 422;

export const UNAUTHORIZED = 401;

export const STATUS_CODE_OK = 200;

export const SERVER_ERRORS_END = 599;

export const IIN_LENGTH = 12;

export const MAX_PASSWORD_LENGTH = 25;

export const DOCUMENT_LENGTH = 9;

export const PLATFORM_CODE = ECreditProductType.SITE;

export const ARENTA_ORGANIZATION_ID = 7;

export const DEFAULT_COORDINATES = { lat: '43.238949', lon: '76.889709' };

export const YANDEX_METRIC_EVENT_KEY = 92189456;

export const DEFAULT_IMAGE_SRC = '/static/images/default_image.png';

export const IMAGE_ALT_CUT_LENGTH = 30;

export const DEFAULT_NAME_PDF = 'file';

export const PLATFORM_ID = 2;

// Возраст клиентов
export const MAX_AGE_CUSTOMER = 80;

export const MIN_AGE_CUSTOMER = 18;

// Даты и числа для дат "выдачи" и "до"
export const MAX_COUNT_YEARS_FOR_EXPIRE_DATE = 10;

export const MIN_YEAR_FOR_EXPIRE_DATE = 1994;

// Регулярное выражение для русских, казахский букв, тире и пробелов
export const REG_EXP_KZ_RU_ALPHABET = /[^а-яӘҒҚҢӨҰҮҺІәғқңөұүhі-\s]/gi;

// Распространенный текст ошибки
export const UNCAUGHT_COMMON_TEXT_ERROR =
    'Возникла непредвиденная техническая ошибка. Пожалуйста, сообщите нам об этом в чате с оператором. Укажите сразу ваш ИИН и те действия, которые привели к ошибке. Мы постараемся максимально быстро разобраться в проблеме';

// Переменные для запросов на проекте
export const PROJECT_SCENARIO = 'rent';

// Статусы заказов
export const ORDERS_STATUSES = {
    APPROVED: 'approved',
    ACTIVE: 'active',
    COURT: 'court',
    EXPIRED: 'expired',
    ISSUED: 'issued',
    CLOSED: 'closed',
    PROCESSING: 'processing',
};

export const ORDERS_STATUSES_ID = {
    COURT: 29,
    EXPIRED: 6,
    ISSUED: 19,
};

export const errorsPageNumbers = {
    [NOT_FOUND_PAGE]: (): JSX.Element => (
        <p>
            Извини, мы не смогли найти такую страницу 😢 <br /> Зато есть другие интересные странички.
            <br /> Например, каталог с товарами специально для тебя 😉
        </p>
    ),
    [SERVER_ERROR]: (): JSX.Element => (
        <p>
            Произошла непредвиденная ошибка 😢 <br /> Зато есть другие интересные странички.
            <br /> Например, каталог с товарами специально для тебя 😉
        </p>
    ),
};

// Действия google и yandex метрик
export enum EMetricEvents {
    IIN_IN = 'iin_in',
    SUCCESSFUL_AUTH = 'isuccessful_authorization',
    FORGOT_PASS = 'forgot_password',
    APPLICATION_CODE_SENT = 'application_code_sent',
    APPLICATION_CREATED = 'application_created',
    ADDED_TO_FAVOURITES = 'added_to_favorites',
    REMOVED_FROM_FAVOURITES = 'removed_from_favorites',
    ADDED_TO_BASKET = 'added_to_basket',
    REMOVED_FROM_BASKET = 'removed_from_basket',
}

export const PRODUCT_TYPE = process.env.NEXT_PUBLIC_PRODUCT_TYPE || '';

export const PRODUCT_CLASSIFIER_ID = process.env.NEXT_PUBLIC_PRODUCT_CLASSIFIER_ID || '';

export const APP_BASE_IMAGE_PATH = process.env.APP_BASE_IMAGE_PATH;

export const APP_BASE_URL = process.env.APP_BASE_URL || '';

export const PUBLIC_PHONE_NUMBER = process.env.PUBLIC_PHONE_NUMBER || '';

export const CONTENT_BLOCKS_PHONE_TEMPLATE = '#PHONE_NUMBER#';

export const SENTRY_DSN = process.env.SENTRY_DSN || '';

export const GTM_TOGGLE = process.env.GTM_TOGGLE === 'true';

export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const SANDSAY_PUSH_LOGIN = process.env.NEXT_PUBLIC_SANDSAY_PUSH_LOGIN;

export const SANDSAY_PUSH_STORAGE_KEY = process.env.NEXT_PUBLIC_SANDSAY_PUSH_STORAGE_KEY;

export const YANDEX_MAP_KEY = process.env.YANDEX_MAP_KEY || '';

export const PRIVACY_POLICY_DOC = process.env.NEXT_PUBLIC_PRIVACY_POLICY_DOC || '';

export const PRIVACY_POLICY_PATH = `${process.env.APP_BASE_IMAGE_PATH}/upload/documents/${PRIVACY_POLICY_DOC}`;
