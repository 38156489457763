import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loadCallTrackPhone } from '../store/action-creators';
import { setCallTrackPhoneCookie } from '../utils';

type TCallTrackState = {
    callTrackPhone: string;
};

const initialState: TCallTrackState = {
    callTrackPhone: '',
};

export const callTrackSlice = createSlice({
    name: 'callTrack',
    initialState,
    reducers: {
        setCallTrackPhone: (state, action: PayloadAction<string>) => {
            setCallTrackPhoneCookie(action.payload);
            state.callTrackPhone = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadCallTrackPhone.fulfilled, (state, action: PayloadAction<string>) => {
            setCallTrackPhoneCookie(action.payload);
            state.callTrackPhone = action.payload;
        });
    },
});

export const { setCallTrackPhone } = callTrackSlice.actions;

export default callTrackSlice.reducer;
