import { ComponentProps, ReactNode, ReactElement, MouseEvent, FC, useState, MouseEventHandler } from 'react';

import { FontSizeButton, VariantButton } from '@/components/elements/UI/button/types';

import { StyledButton } from './styled';

type TProps = ComponentProps<'button'> & {
    children: ReactNode;
    className?: string;
    variant?: VariantButton;
    fontSize?: FontSizeButton;
    icon?: ReactElement | string;
    outlined?: boolean;
    isLoading?: boolean;
    stretch?: boolean;
    weight?: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    tabIndex?: number;
    disabled?: boolean;
    iconPos?: 'right' | 'left';
    isAwaitLoading?: boolean;
};

export const Button: FC<TProps> = ({
    className = '',
    variant = 'neutral',
    fontSize = 'sm',
    outlined = false,
    children,
    onClick,
    isLoading = false,
    tabIndex = 0,
    id,
    disabled,
    stretch = false,
    weight = '500',
    icon,
    iconPos = 'left',
    isAwaitLoading = false,
}) => {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        if (typeof onClick === 'function') {
            if (isAwaitLoading) {
                setIsLoadingLocal(true);
                await onClick(event);
                setIsLoadingLocal(false);
            } else {
                onClick(event);
            }
        }
    };

    return (
        <StyledButton
            disabled={disabled || isLoading || isLoadingLocal}
            $isLoading={isLoading || isLoadingLocal}
            tabIndex={tabIndex}
            id={id}
            className={className}
            $variant={variant}
            $fontSize={fontSize}
            $outlined={outlined}
            onClick={handleClick}
            $stretch={stretch}
            $weight={weight}
        >
            {icon && iconPos === 'left' && <span className="icon">{icon}</span>}
            {isLoading ? <span className="hidden"> {children}</span> : children}
            {icon && iconPos === 'right' && <span className="icon icon-right">{icon}</span>}
        </StyledButton>
    );
};
