import { theme } from 'antd';
import { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme as themeConstants } from '@/shared/theme';

export const ThemeProviderWrapper = ({ children }: PropsWithChildren) => {
    const { token } = theme.useToken();
    const myTheme = { ...themeConstants, antd: token };

    return <ThemeProvider theme={myTheme}>{children}</ThemeProvider>;
};
