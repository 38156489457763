import { TPaginationParams } from '@/api/sass-credit/types';

export enum EStatusProduct {
    ACTIVE = 'active',
    DRAFT = 'draft',
    SOLD = 'sold',
    ONSALE = 'on_sale',
    WRITTENOFF = 'written_off',
    LEASED = 'leased',
    RESERVED = 'reserved',
    CREATED = 'created',
}

export type TGetRentProductsParams = TPaginationParams & {
    ids?: string[];
    popular?: boolean;
    slugs?: string[];
    katoid?: string;
    statuses?: EStatusProduct[];
    category_id?: number;
    category_slug?: string;
    name?: string;
};
