import { ResourceService } from '@/api/baseServices/resource-service';
import { axiosClientSCClass } from '@/api/sass-credit/axios';
import { TGetCitiesParams, TResponseCitiesWithMeta } from '@/api/sass-credit/citiesService/types';

export const CitiesService = ResourceService('v1/cities', axiosClientSCClass);

export const getCities = async (params?: TGetCitiesParams): Promise<TResponseCitiesWithMeta> => {
    const responseProducts = await CitiesService.read<TResponseCitiesWithMeta, TGetCitiesParams>({
        params,
    });

    return responseProducts;
};
