import { getCookie, deleteCookie, setCookie, hasCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { isJSON } from '@/utils/json';

/**
 *  Cookies.get('key'); or  Cookies.get('key', { path: '/path', domain: '.yourdomain.com' }) -- client side
 *  Cookies.get('key', { req, res }); or  Cookies.get('key', { req, res, path: '/path', domain: '.yourdomain.com' }) - server side
 *
 *  Cookies.set('key', 'value'); or Cookies.set('key', 'value', { path: '/path', domain: '.yourdomain.com' }) - client side
 *  Cookies.se('key', 'value', { req, res }); or Cookies.se('key', 'value', { req, res, path: '/path', domain: '.yourdomain.com' }) - server side
 *
 *  Cookies.remove('key'); - client side
 *  Cookies.remove('key', { req, res }); - server side
 *
 *  Cookies.has('key); - client side
 *  Cookies.has('key', { req, res }); - server side
 */

export const Cookies = {
    get: <T>(key: string, options?: OptionsType | undefined): T => {
        const value = getCookie(key, options);

        return isJSON(value as string) ? JSON.parse(value as string) : value;
    },

    set: <T>(key: string, value: T, options?: OptionsType | undefined): void => {
        setCookie(key, JSON.stringify(value), options);
    },

    remove: (key: string | string[], options?: OptionsType | undefined): void => {
        try {
            const keys = Array.isArray(key) ? key : [key];

            keys.forEach((key) => deleteCookie(key, options));
        } catch (err) {
            // Te
        }
    },
    has: (key: string, options?: OptionsType | undefined): boolean | undefined => {
        return hasCookie(key, options);
    },
};
