import { Router } from 'next/router';
import { useState } from 'react';

export const useRouteChange = (): { isRouteChanging: boolean } => {
    const [isChanging, setIsChanging] = useState(false);

    Router.events.on('routeChangeStart', () => setIsChanging(true));

    Router.events.on('routeChangeComplete', () => setIsChanging(false));

    Router.events.on('routeChangeError', () => setIsChanging(false));

    return {
        isRouteChanging: isChanging,
    };
};
