import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TVerificationMethod } from '../api/types';

import { loadVerificationMessage } from './action-creators';

type TVerificationServiceState = {
    verificationMethod: TVerificationMethod | null;
    message: string | undefined;
    title: string | undefined;
    phone: string;
};

const initialState: TVerificationServiceState = {
    verificationMethod: null,
    message: '',
    title: '',
    phone: '',
};

export const verificationServiceSlice = createSlice({
    name: 'verification-service',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            loadVerificationMessage.fulfilled,
            (state, action: PayloadAction<TVerificationServiceState>) => {
                state.verificationMethod = action.payload.verificationMethod;
                state.message = action.payload.message;
                state.title = action.payload.title;
                state.phone = action.payload.phone;
            },
        );
    },
});

export default verificationServiceSlice.reducer;
