import { createSlice } from '@reduxjs/toolkit';

import { Basket } from '@/store/reducers/basket/types';

import { loadBasketProducts } from './action-creators';

const initialState: Basket = {
    isEmpty: false,
    itemsBasket: [],
    isLoaded: false,
    activeProduct: null,
};

export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        toggleEmpty(state) {
            state.isEmpty = !state.isEmpty;
        },
        addProductBasket(state, action) {
            state.itemsBasket.unshift(action.payload.product);
        },
        deleteProductBasket(state, action) {
            state.itemsBasket = state.itemsBasket.filter((el) => el.id !== action.payload.id);
        },
        changeStatusInFavorite(state, action) {
            state.itemsBasket = state.itemsBasket.map((el) =>
                el.id === action.payload.id ? { ...el, favorite: true } : el,
            );
        },
        addBasketSubmitProduct(state, action) {
            state.activeProduct = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Processing register request
            .addCase(loadBasketProducts.pending, (state) => {
                state.isLoaded = false;
            })
            .addCase(loadBasketProducts.rejected, (state) => {
                state.isLoaded = true;
            })
            .addCase(loadBasketProducts.fulfilled, (state, { payload }) => {
                state.itemsBasket = payload;

                state.isLoaded = true;
            });
    },
});

export const { toggleEmpty, addProductBasket, changeStatusInFavorite, deleteProductBasket, addBasketSubmitProduct } =
    basketSlice.actions;

export default basketSlice.reducer;
