import React from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from 'styled-components';

import { StyledLoader, StyledRouteSpinner } from './styled';

export const LoaderRoute: React.FC = () => {
    const theme = useTheme();

    return createPortal(
        <StyledRouteSpinner>
            <StyledLoader>
                <svg viewBox="0 0 100 100">
                    <defs>
                        <filter id="shadow">
                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#eee" />
                        </filter>
                    </defs>
                    <circle
                        id="spinner"
                        style={{
                            fill: 'transparent',
                            stroke: theme.color.primary,
                            strokeWidth: '7px',
                            strokeLinecap: 'round',
                            filter: 'url(#shadow)',
                        }}
                        cx="50"
                        cy="50"
                        r="45"
                    />
                </svg>
            </StyledLoader>
        </StyledRouteSpinner>,
        document.getElementById('route-spinner') as HTMLDivElement,
    );
};
