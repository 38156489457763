import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TSignInSteps } from '@/components/modules/sign-in-page/types';

import { TSignInsState, TSignInTypes } from './types';

const initialState: TSignInsState = {
    type: 'login',
    step: 'login',
    iin: '',
    phone: '',
    verificationCode: '',
};

export const signInSlice = createSlice({
    name: 'signIn',
    initialState,
    reducers: {
        changeTypeSignIn(state, action: PayloadAction<TSignInTypes>) {
            state.type = action.payload;
        },
        restoreInformationSignIn(state) {
            state.type = 'login';

            state.step = 'login';

            state.iin = '';

            state.phone = '';

            state.verificationCode = '';
        },
        changeStepSignIn(state, action: PayloadAction<TSignInSteps>) {
            state.step = action.payload;
        },
        changeValuesSignIn(state, action: PayloadAction<{ iin?: string; phone?: string; verificationCode?: string }>) {
            state.iin = action.payload.iin || state.iin;

            state.phone = action.payload.phone || state.phone;

            state.verificationCode = action.payload.verificationCode || state.verificationCode;
        },
    },
});

export const { changeTypeSignIn, changeStepSignIn, changeValuesSignIn, restoreInformationSignIn } = signInSlice.actions;

export default signInSlice.reducer;
