import { captureException } from '@sentry/browser';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { axiosClientAuthClass } from '@/api/auth/axios';
import { UNAUTHORIZED } from '@/constants/settings';
import { COUNT_REFRESH_TOKEN, STORAGE_KEYS } from '@/constants/storage-keys';
import { TResponseAndRequest } from '@/types/types';
import { Cookies } from '@/utils/cookie';

type TTokenServiceInterface = {
    saveAccessToken: (token: string, props?: TResponseAndRequest) => void;
    saveRefreshToken: (token: string, props?: TResponseAndRequest) => void;
    getAccessToken: (props?: TResponseAndRequest) => string;
    getRefreshToken: (props?: TResponseAndRequest) => string;
    removeAccessToken: (props?: TResponseAndRequest) => void;
    removeRefreshToken: (props?: TResponseAndRequest) => void;
    setCountRefreshToken: (count: number, props?: TResponseAndRequest) => void;
    getCountRefreshToken: (props?: TResponseAndRequest) => number;
    removeCountRefreshToken: (props?: TResponseAndRequest) => void;
    removeTokens: () => void;
};

export const TokenService: TTokenServiceInterface = {
    saveAccessToken: (token, props) =>
        Cookies.set(STORAGE_KEYS.accessToken, token, { maxAge: 60 * 60 * 24 * 365, ...props }),
    saveRefreshToken: (token, props) =>
        Cookies.set(STORAGE_KEYS.refreshToken, token, { maxAge: 60 * 60 * 24 * 365, ...props }),
    getAccessToken: (props) => Cookies.get<string>(STORAGE_KEYS.accessToken, props),
    getRefreshToken: (props) => Cookies.get<string>(STORAGE_KEYS.refreshToken, props),
    removeAccessToken: (props) => Cookies.remove(STORAGE_KEYS.accessToken, props),
    removeRefreshToken: (props) => Cookies.remove(STORAGE_KEYS.refreshToken, props),
    setCountRefreshToken: (count, props) => Cookies.set(STORAGE_KEYS.countRefreshToken, count, props),
    getCountRefreshToken: (props) =>
        Cookies.get<string>(STORAGE_KEYS.countRefreshToken, props)
            ? +Cookies.get<string>(STORAGE_KEYS.countRefreshToken, props)
            : 0,
    removeCountRefreshToken: (props) => Cookies.remove(STORAGE_KEYS.countRefreshToken, props),
    removeTokens: () => {
        Cookies.remove([STORAGE_KEYS.accessToken, STORAGE_KEYS.refreshToken]);
    },
};

// Настройки для токена axios

const failedRequestStack: ((param: string) => void)[] = [];

let refreshingToken = false;

const retryRequests = (accessToken: string) => {
    while (failedRequestStack.length) {
        failedRequestStack.pop()?.(accessToken);
    }
};

export const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const mustRefreshToken = (url: string, context?: TResponseAndRequest): boolean => {
    const excludePath = ['/auth/refresh', '/sign-in'];

    const _url = url.split('?')[0];

    if (excludePath.includes(_url)) {
        return false;
    }

    const count = TokenService.getCountRefreshToken(context);

    if (count > COUNT_REFRESH_TOKEN) {
        TokenService.removeAccessToken(context);

        TokenService.removeRefreshToken(context);

        TokenService.removeCountRefreshToken(context);

        return false;
    }

    TokenService.setCountRefreshToken(count + 1, context);

    return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const onResponseError =
    (axiosClient: AxiosInstance, context?: TResponseAndRequest) =>
    async (err: AxiosError): Promise<unknown> => {
        const originalConfig = err.config;
        // Intended to show 500 error when server fails

        const contextCookie = context;

        // Return early if there was no token provided in the first place(meaning that user is not authenticated or did not have stale token)
        if (!originalConfig?.headers?.Authorization) {
            return Promise.reject(err);
        }

        // Access Token was expired
        if (err.response && err.response.status === UNAUTHORIZED && mustRefreshToken(originalConfig?.url as string)) {
            const refreshToken = TokenService.getRefreshToken(contextCookie);

            TokenService.removeAccessToken(contextCookie);

            TokenService.removeRefreshToken(contextCookie);

            if (!refreshingToken && refreshToken) {
                const retryOrigReq = new Promise((resolve) => {
                    failedRequestStack.push((token) => {
                        originalConfig.headers!['Authorization'] = 'Bearer ' + token;

                        resolve(axiosClient(originalConfig));
                    });
                });

                try {
                    refreshingToken = true;

                    const rs = await axiosClientAuthClass.use().post('/auth/refresh', {
                        refresh_token: refreshToken,
                    });

                    const { token, refresh_token } = rs.data;

                    TokenService.saveAccessToken(token, contextCookie);

                    TokenService.saveRefreshToken(refresh_token, contextCookie);

                    retryRequests(token);
                } catch (_error) {
                    captureException(_error);

                    return Promise.reject(_error);
                } finally {
                    refreshingToken = false;
                }

                return await retryOrigReq;
            }
        }

        return Promise.reject(err);
    };
