import { ConfigProvider } from 'antd';
import localeRuAntd from 'antd/locale/ru_RU';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

import { RouteChangeSpinner } from '@/components/elements/route-change-spinner';
import { DeniedLocalStorage } from '@/components/modules/denied-local-storage';
import { TechnicErrorProvider } from '@/components/modules/technic-error-provider';
import { wrapperReduxToolkit, useAppDispatch } from '@/store';
import { loadBasketProducts } from '@/store/reducers/basket/action-creators';
import { loadFavoriteProducts } from '@/store/reducers/favorite/action-creators';
import { GlobalStyle } from '@/styles/global.styled';
import { isStorageDenied } from '@/utils/check-storage-denied';

import { useCallTrackInit } from '@/features/callback';

import { useHistorySaveUrl } from '@/shared/hooks/use-history-save-url';
import { useUtmSourceService } from '@/shared/hooks/use-utm-source-service';
import { theme } from '@/shared/theme';

import LangKaz from './locales/kk/common.json';
import LangRus from './locales/ru/common.json';
import { ErrorWrapper } from './wrappers/error-wrapper';
import { ThemeProviderWrapper } from './wrappers/theme-wrapper';

export const fontFamily = '"Rounded Mplus 1c", sans-serif';

const ModalsModule = dynamic(() => import('../components/modules/modals'), { ssr: false });

export const App = wrapperReduxToolkit.withRedux(({ Component, pageProps }: AppProps) => {
    const [isStorageDeniedState, setStorageDeniedState] = useState(false);
    const { locale, defaultLocale } = useRouter();
    const dispatch = useAppDispatch();

    useHistorySaveUrl();
    useUtmSourceService();
    useCallTrackInit();

    const messages = useMemo(() => {
        switch (locale) {
            case 'ru':
                return LangRus;
            case 'kk':
                return LangKaz;
            default:
                return LangRus;
        }
    }, [locale]);

    useEffect(() => {
        dispatch(loadFavoriteProducts());
        dispatch(loadBasketProducts());
    }, []);

    useEffect(() => {
        setStorageDeniedState(isStorageDenied);
    }, []);

    return (
        <ConfigProvider
            locale={localeRuAntd}
            theme={{
                token: {
                    colorPrimary: theme.color.primary,
                    fontFamily,
                },
            }}
        >
            <ThemeProviderWrapper>
                <IntlProvider
                    /*
                     * Удалить потом onError когда переводы будут иначе куча ошибок в консоле браузера что не найден перевод
                     */
                    /* eslint-disable @typescript-eslint/no-empty-function */
                    onError={() => {}}
                    locale={locale as string}
                    defaultLocale={defaultLocale}
                    messages={messages as Record<string, string> | Record<string, MessageFormatElement[]>}
                >
                    <ErrorWrapper>
                        {isStorageDeniedState ? (
                            <DeniedLocalStorage />
                        ) : (
                            <>
                                <GlobalStyle />
                                <RouteChangeSpinner />
                                <TechnicErrorProvider>
                                    <Component {...pageProps} />
                                    <ModalsModule />
                                </TechnicErrorProvider>
                            </>
                        )}
                    </ErrorWrapper>
                </IntlProvider>
            </ThemeProviderWrapper>
        </ConfigProvider>
    );
});
