import React, { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setTechnicalError } from '@/store/reducers/settings/slice';

import { useShowTechnicalError } from '@/shared/hooks/use-show-technical-error';

type TTechnicErrorProps = {
    children: React.ReactNode;
};

export const TechnicErrorProvider: FC<TTechnicErrorProps> = ({ children }) => {
    const error = useAppSelector((state) => state.settings.technicalError);

    const { showError } = useShowTechnicalError();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (error !== null) {
            showError();

            // TODO костыль ARKZ-235
            dispatch(setTechnicalError(null));
        }
    }, [error]);

    return <>{children}</>;
};
