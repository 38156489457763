var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ddb5xqtr3eJuJmzLE2xMU"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN } from '@/constants/settings';

Sentry.init({
    dsn: SENTRY_DSN,
    tracePropagationTargets: [
        'http://localhost:3000',
        'https://arenta.kz',
        'https://test-ar-kz.dengi-market.kz',
        /^\/api\//,
    ],
    integrations: [
        new Sentry.Replay(),
        new Sentry.BrowserTracing(),
        new Sentry.Integrations.FunctionToString(),
        new Sentry.Integrations.InboundFilters(),
        new Sentry.Integrations.Breadcrumbs(),
        new Sentry.Integrations.TryCatch({
            setTimeout: false,
            setInterval: true,
            requestAnimationFrame: true,
            XMLHttpRequest: true,
            eventTarget: true,
        }),
        new Sentry.Integrations.LinkedErrors(),
        new Sentry.Integrations.GlobalHandlers(),
        new Sentry.Integrations.HttpContext(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
