/* eslint-disable camelcase */
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TRegistrationValuesForm } from '@/components/modules/registration/types';
import { PROJECT_SCENARIO, PRODUCT_TYPE } from '@/constants/settings';
import { TResponseAndRequest } from '@/types/types';
import { TCreatedCustomer, TLeadUser } from '@/types/user';
import { formatDateToReverse } from '@/utils/format-date';

import { ResourceService, TErrorData, TReadParamsProperties } from '../baseServices/resource-service';

import { axiosClientSCClass } from './axios/index';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LeadsService = (context?: TResponseAndRequest) => ResourceService('v1/leads', axiosClientSCClass, context);

export type TPostLeadResponse = {
    id: string;
};

export type TLeadByPartnersResponse = {
    current_state: string;
    phone_number: string;
    source_type: string;
    utm_source: string;
    referer: string;
    create_date: string;
    id: number;
    familiars: unknown[];
    registrationAddress: unknown;
    residentialAddress: unknown;
    is_gender_valid: boolean;
};

export const createLeadByPartners = async ({
    phone,
    referer,
    rentProductId,
}: {
    phone: string;
    referer: string;
    rentProductId: number;
}): Promise<TLeadByPartnersResponse> => {
    const responseUpdatedLeadData = await LeadsService().command<TLeadByPartnersResponse, TReadParamsProperties>({
        command: 'create-by-partners',
        data: {
            phone_number: `+${phone}`,
            source_type: 'SC',
            utm_source: 'arenta_sc',
            rent_product_id: rentProductId,
            referer,
        },
    });

    return responseUpdatedLeadData;
};

export const createLead = async ({
    iin,
    phone,
}: {
    iin: string;
    phone: string;
}): Promise<TPostLeadResponse | TErrorData> => {
    try {
        const responseLead = await LeadsService().create<TPostLeadResponse, TReadParamsProperties>({
            data: {
                iin,
                phone_number: `+${phone}`,
                source_type: 'RENT',
                utm_source: 'arenta',
            },
            params: {
                product: PRODUCT_TYPE,
            },
        });

        return responseLead;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};

export const updateLeadData = async (
    values: TRegistrationValuesForm,
    withAdditionalData: boolean,
): Promise<TLeadUser | TErrorData> => {
    const { name, patronymic, surname, gender, dateBirthday } = values;

    const additionalData = withAdditionalData ? {} : { gender: gender, date_of_birth: dateBirthday };

    try {
        const responseUpdatedLeadData = await LeadsService().patch<TLeadUser, TReadParamsProperties>({
            identifier: 'me',
            data: {
                first_name: name,
                last_name: surname,
                middle_name: patronymic,
                ...additionalData,
            },
            params: {
                product: PRODUCT_TYPE,
            },
        });

        return responseUpdatedLeadData;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};

export const updateDocumentLeadData = async (values: TRegistrationValuesForm): Promise<TLeadUser | TErrorData> => {
    const { documentNumber, dateDocument, placeDocument, validDocument } = values;

    try {
        const responseUpdatedLeadData = await LeadsService().command<TLeadUser, TReadParamsProperties>({
            command: 'me/identifications',
            data: {
                document_type: 'id_card',
                number: documentNumber,
                issue_place: placeDocument,
                issue_date: formatDateToReverse(dateDocument),
                expire_date: formatDateToReverse(validDocument),
            },
            params: {
                product: PRODUCT_TYPE,
            },
        });

        return responseUpdatedLeadData;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return error?.response?.data as TErrorData;
    }
};

export const createCustomerUserFromLead = async (): Promise<TCreatedCustomer> => {
    const responseUpdatedLeadData = await LeadsService().command<TCreatedCustomer, TReadParamsProperties>({
        command: 'me/create-customer',
        params: {
            scenario: PROJECT_SCENARIO,
            product: PRODUCT_TYPE,
        },
    });

    return responseUpdatedLeadData;
};

export const getLead = async (context?: TResponseAndRequest): Promise<TLeadUser> => {
    const responseLead = await LeadsService(context).command<TLeadUser, TReadParamsProperties>({
        command: 'me',
        method: 'GET',
        params: {
            product: PRODUCT_TYPE,
        },
    });

    return responseLead;
};
