/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { loadSocialLinks } from '@/components/modules/social-links/store/action-creactors';

import { TSocialLinksState } from './types';

const initialState: TSocialLinksState = {
    data: [],
};

export const socialLinksSlice = createSlice({
    name: 'socialLinks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadSocialLinks.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    },
});

export default socialLinksSlice.reducer;
