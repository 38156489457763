import { TSocialLink } from '@/types/settings';

import { ResourceService } from '../baseServices/resource-service';

export const SocialNetworksService = ResourceService('social-networks');

export const getSocialLinks = async (): Promise<TSocialLink[]> => {
    const response = await SocialNetworksService.read<{ data: TSocialLink[] }>();

    return response.data;
};
