export const mq = {
    xs: '@media screen and (max-width: 576px)',
    sm: '@media screen and (min-width: 577px) and (max-width: 768px)',
    md: '@media screen and (min-width: 769px) and (max-width: 992px)',
    lg: '@media screen and (min-width: 993px) and (max-width: 1200px)',
    xl: '@media screen and (min-width: 1201px) and (max-width: 1600px)',
    xxl: '@media screen and (min-width: 1601px) and (max-width: 5000px)',

    //Меньше или равна
    lt: {
        sm: '@media screen and (max-width: 575px)',
        md: '@media screen and (max-width: 767px)',
        lg: '@media screen and (max-width: 991px)',
        xl: '@media screen and (max-width: 1199px)',
    },
    //Больше или равна
    gt: {
        xs: '@media screen and (min-width: 576px)',
        sm: '@media screen and (min-width: 768px)',
        md: '@media screen and (min-width: 992px)',
        lg: '@media screen and (min-width: 1200px)',
    },
};
