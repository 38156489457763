/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

import { loadMenus } from '@/store/reducers/menus/action-creators';
import { TMenusState, TMenu, TMenuList } from '@/store/reducers/menus/types';

const initialState: TMenusState = {
    menu: { data: null, isFetching: false, isLoaded: false },
};

export const menusSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadMenus.fulfilled, (state, { payload }) => {
                const mobileMenu: TMenuList | undefined = payload?.find((el) => el.name === 'mobile_menu');

                const headerMenu: TMenuList | undefined = payload?.find((el) => el.name === 'header_menu');

                const footerMenuArticles: TMenuList | undefined = payload?.find(
                    (el) => el.name === 'footer_menu_articles',
                );

                const footerMenuCatalog: TMenuList | undefined = payload?.find(
                    (el) => el.name === 'footer_menu_catalog',
                );

                const objHeaderMenu: TMenu = {
                    footerMenuCatalog,
                    footerMenuArticles,
                    headerMenu,
                    mobileMenu,
                };

                state.menu.isFetching = false;

                state.menu.isLoaded = true;

                state.menu.data = objHeaderMenu as TMenu;
            })
            .addCase(loadMenus.pending, (state) => {
                state.menu.isFetching = true;

                state.menu.isLoaded = false;
            })
            .addCase(loadMenus.rejected, (state) => {
                state.menu.isFetching = false;

                state.menu.isLoaded = false;

                state.menu.data = null;
            });
    },
});

export default menusSlice.reducer;
