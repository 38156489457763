import { combineReducers } from '@reduxjs/toolkit';

import { calculationFormReducer } from '@/components/modules/caclulator';
import { socialLinksReducer } from '@/components/modules/social-links';
import basketReducer from '@/store/reducers/basket/slice';
import citiesReducer from '@/store/reducers/cities/slice';
import contentBlocksReducer from '@/store/reducers/content-blocks/slice';
import creditProductReducer from '@/store/reducers/credit-products/slice';
import menusReducer from '@/store/reducers/menus/slice';
import settingsReducer from '@/store/reducers/settings/slice';

import { callTrackReducer } from '@/features/callback';
import { verificationServiceReducer } from '@/features/code-verification';

import { errorReducer } from './errors';
import favoriteReducer from './favorite/slice';
import modalReducer from './modal/slice';
import officeReducer from './office/slice';
import productReducer from './product/slice';
import rentReducer from './rent/slice';
import signInReducer from './sign-in';
import userReducer from './user/slice';

export const rootReducer = combineReducers({
    siteModal: modalReducer,
    favorite: favoriteReducer,
    product: productReducer,
    user: userReducer,
    basket: basketReducer,
    settings: settingsReducer,
    office: officeReducer,
    signIn: signInReducer,
    rent: rentReducer,
    creditProducts: creditProductReducer,
    menus: menusReducer,
    contentBlocks: contentBlocksReducer,
    cities: citiesReducer,
    calculatorForm: calculationFormReducer,
    calltrack: callTrackReducer,
    socialLinks: socialLinksReducer,
    verificationService: verificationServiceReducer,
    error: errorReducer,
});
