import { createSlice } from '@reduxjs/toolkit';

import { loadFavoriteProducts } from './action-creators';
import { TFavorite } from './types';

const initialState: TFavorite = {
    itemsFavorite: [],
    isLoaded: false,
};

export const favoriteSlice = createSlice({
    name: 'favorite',
    initialState,
    reducers: {
        addProductFavorite(state, action) {
            state.itemsFavorite.unshift(action.payload.product);
        },
        deleteProductFavorite(state, action) {
            state.itemsFavorite = state.itemsFavorite.filter((el) => el.id !== action.payload.id);
        },
        changeStatusInBasket(state, action) {
            state.itemsFavorite = state.itemsFavorite.map((el) =>
                el.id === action.payload.id ? { ...el, isInBasket: true } : el,
            );
        },
    },
    extraReducers: (builder) => {
        builder
            // Processing register request
            .addCase(loadFavoriteProducts.pending, (state) => {
                state.isLoaded = false;
            })
            .addCase(loadFavoriteProducts.rejected, (state) => {
                state.isLoaded = true;
            })
            .addCase(loadFavoriteProducts.fulfilled, (state, { payload }) => {
                state.itemsFavorite = payload;

                state.isLoaded = true;
            });
    },
});

export const { addProductFavorite, changeStatusInBasket, deleteProductFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
