import { useEffect } from 'react';

import { setHistoryUrl } from '@/shared/hooks/use-history-save-url/utils';
import { useMounted } from '@/shared/hooks/use-mounted';

export const useHistorySaveUrl = (): void => {
    const isMounted = useMounted();

    useEffect(() => {
        if (isMounted()) {
            setHistoryUrl(window.location.pathname);
        }
    });
};
