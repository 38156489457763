export const STORAGE_KEYS = {
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
    countRefreshToken: 'count_refresh_token',
    redirectPath: 'redirect_path',
    userCity: 'user_city',
    favoriteProducts: 'favorite_products',
    basketProducts: 'basket_products',
};

export const COUNT_REFRESH_TOKEN = 4;
