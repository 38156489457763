export const checkStorageDenied = () => {
    try {
        localStorage;
        return false;
    } catch (e) {
        return true;
    }
};

export const isStorageDenied = checkStorageDenied();
