import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Modal } from '@/store/reducers/modal/types';

const initialState: Modal = {
    hasShow: false,
    onClose: null,
    content: null,
    size: 'Medium',
    type: 'Default',
    title: null,
    screenShowNotification: 'md',
};

export const siteModalSlice = createSlice({
    name: 'modal', //FIXME maybe should be stored in constants
    initialState,
    reducers: {
        closeModal(state) {
            state.hasShow = false;
        },

        showModal(state, action: PayloadAction<Omit<Modal, 'hasShow'>>) {
            state.content = action.payload.content;
            state.type = action.payload.type || initialState.type;
            state.onClose = action.payload.onClose || null;
            state.size = action.payload.size || initialState.size;
            state.title = action.payload.title;
            state.screenShowNotification = action.payload.screenShowNotification ?? 'md';
            state.hasShow = true;
        },
    },
});

export const { closeModal, showModal } = siteModalSlice.actions;

export default siteModalSlice.reducer;
