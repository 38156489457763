/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TIinParams, TUserByIinResponse, UserArentaService } from '@/api/arenta/user-service';
import { AuthService } from '@/api/auth/auth-service';
import { TErrorData, TReadParamsProperties } from '@/api/baseServices/resource-service';
import { TokenService } from '@/api/baseServices/tokenService/token-service';
import {
    checkIsNewQuestionnaireAllowed,
    getUserProfileData,
    TIsNewQuestionnaireAllowed,
} from '@/api/sass-credit/customers-service';
import { checkUserByIin } from '@/api/sass-credit/iin-service';
import { getLead } from '@/api/sass-credit/leads-service';
import { TResponseAndRequest } from '@/types/types';
import { TFindCustomerType, TLeadUser, TLoginUserParams, TUserProfileData } from '@/types/user';
import { Tokens } from '@/types/utils';

import { setTechnicalError } from '../settings/slice';

const loadUserData = createAsyncThunk<
    TUserProfileData | TLeadUser | undefined,
    TResponseAndRequest | undefined,
    { rejectValue: unknown }
>('users/loadUserData', async function (context: TResponseAndRequest | undefined, { rejectWithValue, dispatch }) {
    const token = TokenService.getAccessToken(context);
    try {
        if (token) {
            return await getUserProfileData(context);
        }
    } catch (error) {
        if (token) {
            return await getLead(context);
        }
        /* eslint-disable no-console */
        console.error('loadUserData error', error);

        // TODO костыль ARKZ-235
        dispatch(setTechnicalError({ error }));

        captureException(error);

        return rejectWithValue(error);
    }
});

export const loadCheckIsNewQuestionnaireAllowed = createAsyncThunk<
    TIsNewQuestionnaireAllowed,
    TResponseAndRequest,
    { rejectValue: TErrorData }
>('users/isNewQuestionnaireAllowed', async function (context: TResponseAndRequest | undefined, { rejectWithValue }) {
    try {
        return await checkIsNewQuestionnaireAllowed(context);
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return rejectWithValue(error.response?.data as TErrorData);
    }
});

export const requestVerifyCounterparty = createAsyncThunk<TFindCustomerType, TIinParams, { rejectValue: TErrorData }>(
    'users/verifyCounterparty',
    async function ({ iin }: TIinParams, { rejectWithValue }) {
        try {
            const data = await checkUserByIin(iin);

            return data;
        } catch (err) {
            const error = err as AxiosError;

            captureException(error);

            return rejectWithValue(error.response?.data as TErrorData);
        }
    },
);

const requestGetUserByINN = createAsyncThunk<
    TUserByIinResponse,
    TIinParams,
    {
        rejectValue: TErrorData;
    }
>('users/getByINN', async ({ iin }: TIinParams, { rejectWithValue }) => {
    try {
        const data = await UserArentaService.readOne<TUserByIinResponse, TReadParamsProperties>({
            identifier: iin,
        });

        return data;
    } catch (err) {
        const error = err as AxiosError;

        captureException(error);

        return rejectWithValue(error.response?.data as TErrorData);
    }
});

const requestLoginUser = createAsyncThunk<Tokens, TLoginUserParams, { rejectValue: TErrorData }>(
    'users/authenticateUser',
    async ({ login, password }: TLoginUserParams, { rejectWithValue }) => {
        try {
            const data = await AuthService.command<Tokens, TReadParamsProperties>({
                command: 'login',
                data: { login, password },
            });

            TokenService.saveAccessToken(data.token);

            TokenService.saveRefreshToken(data.refresh_token);

            return data;
        } catch (err) {
            const error = err as AxiosError;

            captureException(error);

            return rejectWithValue(error?.response?.data as TErrorData);
        }
    },
);

export const requestGetLead = createAsyncThunk<TLeadUser, TIinParams, { rejectValue: TErrorData }>(
    'users/getLead',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function ({ iin }: TIinParams, { rejectWithValue }) {
        try {
            const data = await getLead();

            return data;
        } catch (err) {
            const error = err as AxiosError;

            captureException(error);

            return rejectWithValue(error.response?.data as TErrorData);
        }
    },
);

export { loadUserData, requestGetUserByINN, requestLoginUser };
